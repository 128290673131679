


import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from '../../../config';
import Axios from 'axios';
import { authHeader } from '../../../services/auth'
import {USER_TYPES} from '../../../constants/constants'
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

@Component({ components: { PerfectScrollbar} })
export default class Users extends Vue{
    public users: any = [];
    public skip = 0;
    public showLoader = false; 
    public name: any = null;
    public type: any = null;
    public email: any = null;
    public isReset = false;
    public curentDataLength = 0;
    public userTypes=USER_TYPES

    public async getUsers(mountCall) {
          mountCall
      ? (this.$store.state.tmpcoLoader = true)
      : (this.showLoader = true);
        try {
            this.skip = mountCall ? 0 : this.skip + 15;
            const response = await Axios.get(`${BASE_API_URL}developer/getUsers`,{ params:{ 
            name: this.isReset ? null : this.name,
            email: this.isReset ? null : this.email,
            type: this.isReset ? null : this.type,
            skip: this.skip,
            limit: 15},
            headers: authHeader() });
            if (response.status === 201) {
              this.isReset = false;
                if(mountCall){
                    this.users = response.data?response.data:[];
                    this.curentDataLength=response.data.length
                }
                else{
                    this.users=response.data?this.users.concat(response.data):this.users
                    this.curentDataLength=response.data.length
                }
            }
        } catch (error) {
          this.isReset = false;
        console.log(error);
        }
         mountCall
      ? (this.$store.state.tmpcoLoader = false)
      : (this.showLoader = false);
    }

  public async scrollHandle(evt) {
    const scroll: any = this.$refs.scrollbar;
    if (
      !this.showLoader &&
      scroll.ps.reach.y == "end" &&
    this.curentDataLength===15
    ) {
      await this.getUsers(false);
    }
  }


    mounted() {
        this.getUsers(true);
    }

}
