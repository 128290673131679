

import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "./../../config";
import { authHeader } from "./../../services/auth";
// import { PerfectScrollbar } from "vue2-perfect-scrollbar";
// import Notification from "../../../common-components/Notification.vue";
import BrokerLoanList from "./../../common-components/loan-list/BrokerLoanList.vue";

@Component({
  components: {
    // Notification,
    BrokerLoanList,
  },
})
export default class DashBoard extends Vue {
  /* eslint-disable */
  public file: any = null;
  public allLoan: any = [];
  public isOnBoardingCompleted: any = null;
  public brokerName: any = null;
  public documents: any = [];
  public loanId: any = "";
  public uploadPercentage: any = 0;
  public formData: any = new FormData();
  public isUploading = false;
  public skip = 0;
  public totalLength = 0;
  public currentLengthOnScroll = 0;
  public loanpurpose: any = null;
  public priorNotification = false;
  public disclosureData: any = {};

  public getXmlFile(event) {
    this.file = event.target.files[0];
  }

  //Uploading xml file & creating new loan.
  public async uploadXml() {
    try {
      let fileType = this.file.name.split(".").pop();
      if (fileType.toLowerCase() != "xml") {
        this.$snotify.error("Invalid File extension.");
        return;
      }
      this.isUploading = true;
      this.uploadPercentage = 0;
      let formData: FormData = new FormData();
      formData.append("file", this.file);
      let response = await Axios.post(
        BASE_API_URL + "broker/uploadMismo",
        formData,
        {
          headers: authHeader(),
          onUploadProgress: function (this: any, progressEvent) {
            this.uploadPercentage = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
          }.bind(this),
        }
      );
      
      if (response.data && response.data.code == 200) {
        this.$snotify.success(response.data.message);
        EventBus.$emit("refres-loan-list", true);
        this.file = null;
        this.$bvModal.hide("new-loan-modal");
      }
      else if(response?.data?.code == 401){
      this.$snotify.info(response.data.message);
      }
      else if(response?.data?.code == 201){
      this.$snotify.info(response.data.message);
      }
       else if(response?.data?.code == 'invalid'){
      this.$snotify.info(response.data.message);
      }
      this.uploadPercentage = 0;
      this.isUploading = false;
    } catch (error) {
      console.log(error);
    }
  }

  public async generateFakeData() {
    try {
      const response = await Axios.post(
        BASE_API_URL + "broker/dashboard/generateFakeData",
        {
          loanId: "ssds",
        },
        {
          headers: authHeader(),
        }
      );
      // console.log(response.data, 'dds');
      this.allLoan = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  public async fetchBrokerDetail() {
    this.$store.state.tmpcoLoader = true;
    try {
      let response = await Axios.get(
        BASE_API_URL + "broker/dashboard/fetchBrokerDetail",
        {
          headers: authHeader(),
        }
      );
      
      this.brokerName = response.data.brokerName;
      this.isOnBoardingCompleted = response.data.isOnBoardingCompleted;
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  // public async getFeeAndPricingDocument(loanId) {
  //   let res = await Axios.post(
  //     BASE_API_URL + "broker/getFeeAndPricingDocument",
  //     {
  //       loanId: loanId
  //     },
  //     {
  //       headers: authHeader()
  //     }
  //   );
  //   this.documents = res.data.documents;
  //   this.loanId = loanId;
  //   this.$bvModal.show("upload-modal");
  // }

  public appendData(event, documentId) {
    let files = event.target.files;
    let docDetails: any = {
      loanId: this.loanId,
      documentId: documentId,
    };
    this.formData.append("loanId", this.loanId);
    this.formData.append("documentId", documentId);
    // this.formData.append("docDetails", docDetails);
    Array.from(files).forEach((f) => {
      this.formData.append("file", f);
    });
  }

  // public async uploadDocument() {
  //   try {
  //     let response = await Axios.post(
  //       BASE_API_URL + "broker/uploadFeeAndPricingDoc",
  //       this.formData,
  //       {
  //         headers: authHeader()
  //       }
  //     );
  //     if (response.status == 201) {
  //       this.$snotify.success("Document has been uploaded.");
  //       this.$bvModal.hide("upload-modal");
  //       this.formData = new FormData();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  cancelModal() {
    this.$bvModal.hide("new-loan-modal");
    if (this.file) this.file = null;
  }

  public getTotalLoanLength(data) {
    this.totalLength = data;
  }

 
  async mounted() {
    await this.fetchBrokerDetail();
  
  }
  /* eslint-disable */
}
